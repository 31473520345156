.standard-product {
    .product-content-block {
        margin-bottom: 0rem !important;
    }

    .product-content-block-copy {


        h3 {
            font-size: 1.5rem !important;
            margin-top: unset;
        }
    }

    .full-gray-fill {
        padding-top: 2rem;
        padding-bottom: 2rem;
        background-color: $light-gray;
        margin-bottom: 4rem;

        .scroll-tab {
            margin-bottom: 0rem;
        }
    }

    #supportmaterials {
        .card {
            a {
                text-decoration: none;
            }
        }
    }
}

.download-icon-wrapper {
    width: auto;
    height: auto;
    position: relative;
}

.download-icon {
    z-index: 9999;
    position: absolute;
    bottom: 7px;
    right: 0px;
    background: $light-gray;
    opacity: 0.5;
}

.download-icon a {
    display: block;
}

.download-icon svg {
    width: 28px;
    height: 28px;
    margin: 0 auto;
    padding: 5px;
    opacity: 0.4;
}

.zoom-gallery .selectors a:hover, .zoom-gallery .selectors a.active {
    border: none;
}

/*Magic Zoom Styles*/
.mz-thumb.mz-thumb-selected {
    img {
        border-bottom-color: $highlight-color !important;
    }
}

.mz-expand .mz-expand-bg {
    display: none !important;
}

.mz-expand {
    background-color: #fff !important;
}

.product-hero {

    h1 {
        border-bottom: .2rem solid $light-gray;
        padding-bottom: .3rem;
    }

    span {
        color: $secondary-gray;
        font-size: 1rem;
        font-weight: 500;
        font-family: $font-family-condensed;
        margin-bottom: -.3rem;
        margin-left: .1rem;
        display: block;
    }

    .product-hero-copy {
        padding-left: 2rem;

        @include media-breakpoint-down(md) {
            padding-left: unset;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 15px;
        }

        ul {
            padding-left: 40px;
        }
    }
}

.stickyTabs {
    top: 51px;
    background-color: #ddd;
    padding-bottom: 1px;

    @include media-breakpoint-down(md) {
        top: 0px;
    }



    ul {
        li {
            list-style-type: none;
        }
    }
}

.product-tabs {
    margin-top: 0px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100%;
}

.tab-container {
    margin-top: 4rem;

    .main-container {
        &.container {
            margin-top: 2rem;
            background-color: $white;
        }
    }

    .scroll-tab {
        margin-bottom: 4rem;
    }

    #specifications {
        .simp-performance {
            margin-top: 3rem;

            @include media-breakpoint-down(md) {
                margin-top: 2rem;
            }
        }

        .simple-rte-block {       
            margin-bottom: 3rem;
        }
    }
}

.dark {
    border:none !important;
}


.product-tabs, .search-tabs {

    .tab-content {

        @include media-breakpoint-down(sm) {
            border-top: none;
        }
    }

    .nav-tabs {
        max-width: 80rem;
        margin-bottom: 0rem;
        border-bottom: none
    }

    .nav-tabs .nav-link {
        font-family: $font-family-condensed;
        font-weight: 400;
        font-size: 1.1rem;
        text-transform: uppercase;
        border: none;
        text-decoration: none;

        @include media-breakpoint-down(md) {
            font-size: .8rem;
        }

        @include media-breakpoint-down(sm) {
            text-align: left;
            color: $header-gray;
        }
    }

    a.nav-link.active {
        background-color: $highlight-color;
        border-radius: 0px;
        text-decoration: none;
        color: $white;

        @include media-breakpoint-down(sm) {
            background-color: $body-color;
            color: $white;
            border-radius: 0px;
        }
    }

    a.nav-link:hover {
        background-color: $highlight-color;
        text-decoration: none;
        border-radius: 0px;
        color: $white;
    }
}


.grid-nav {
    a {
        text-decoration: none;
    }

    h2 {
        padding-left: 7px;
        padding-bottom: 4px;
        margin-bottom: 1rem;
        margin-top: -5px;
        border-bottom: 2px solid $highlight-color;
    }

    h3 {
        color: $header-gray;
        border-bottom: 2px solid $light-gray;
        padding-bottom: .4rem;
        margin-bottom: 1rem;
        margin-left: 7px;
        font-weight: 500;
        font-size: 1.3rem !important;
    }

    h4 {
        font-size: 1.3rem !important;
        margin-left: 10px;
    }
}


/*Downloads Tab*/

#supportmaterials {
    h3 {
        font-size: 1.5rem;
    }
}
.tooltip-inner {
    background-color: $light-gray;
    padding: 7px;
}

.tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.bs-tooltip-left .arrow::before {
    border-left-color: $light-gray;
}

.featured-download {
    img {
        @include media-breakpoint-down(sm) {
            width: 80px;
        }
    }
}

.downloads-category {
    padding-bottom: 7px;
}


.downloads-table {
    margin-bottom: 2.5rem;

    td {
        padding: .45rem;

        @include media-breakpoint-down(sm) {
            font-size: .8rem;
        }
    }

    thead {
        th {
            white-space: nowrap;
            padding-top: 0rem;
            padding-bottom: .3rem;
            padding-left: .45rem;
            padding-right: .45rem;

            @include media-breakpoint-down(sm) {
                font-size: .8rem;
                padding: .35rem;
            }
        }
    }
}
.table-hover tbody tr:hover {
    background-color: $highlight-color;
}

.document-card-span {
    font-size: .9rem;
    text-align: left;
    display: block;
    color: $secondary-gray;

    @include media-breakpoint-down(sm) {
        font-size: .75rem;
    }
}

.document-card-link {
    display: block;
    margin: 9px 0px 9px 0px;
    font-size: 1rem;
    font-weight: 400;

    @include media-breakpoint-down(sm) {
        font-size: .75rem;
    }
}

.tooltip.show {
    opacity: 1;
}
.card {
    background-color: transparent;
}
.card-wrapper {
    font-weight: 400;
    font-family: $font-family-compressed;
    font-size: 1.2rem;
    line-height: initial;

    @include media-breakpoint-down(lg) {
        font-size: 1rem;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .card-body {
        span {
            font-weight: 800;
        }
    }
}

.card-body-large {
    display: inline-block;

    span {
        font-weight: 400;
        font-family: $font-family-compressed;
        vertical-align: top;
        padding-top: 1rem;
    }

    p {
        @include media-breakpoint-down(sm) {
            font-size: 1rem;
            line-height: 1.3rem;
        }
    }
}

.product-icon {
    img {
        width: 150px;
        margin-right: 1rem;

        @include media-breakpoint-down(md) {
            width: 100px;
            margin-right: 0.6rem;
        }

        @include media-breakpoint-down(sm) {
            width: 60px;
            margin-right: 0.6rem;
        }
    }
}

.product-silo {
    .full-gray-fill {
        margin-top: -74px;
    }
}
