.feature-banner-block {
    background-size: cover;
    min-height: 250px;
    max-height: 250px;
    margin-bottom: $standard-block-bottom-margin;
    color: $white;
    padding: 40px;

    @include media-breakpoint-down(sm) {
        min-height: 250px;
        max-height: unset;
        padding: 20px;
    }

    .primary-col {

        @include media-breakpoint-down(sm) {
            margin-bottom: 1rem;
        }
    }

    .secondary-col {
        @include media-breakpoint-down(sm) {
        }
    }
}
