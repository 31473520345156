.multi-column-block {
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .fe-btn {
        white-space: normal;
        line-height: 1.5rem;

        @include media-breakpoint-down(lg) {
            padding: 5px 12px 7px 12px;
            font-size: 1.2rem;
            line-height: 1rem;
        }
    }
}
