
.navbar {

    .global-navbar button span {
        font-family: $font-family-compressed;
        font-weight: 400;
        letter-spacing: .05em;
        z-index: 999;
        text-transform: uppercase;
    }

    .navbar-collapse {
        .container {
            justify-content: right;

            @include media-breakpoint-down(md) {
                padding: 0 !important;
            }

            .navbar-nav-old {
                display: none;
            }

            .navbar-nav-alt {
                flex-direction: row;

                li a {
                    font-family: $font-family-menu;
                    font-weight: 600;
                    font-size: 1.25rem;
                    letter-spacing: .05em;
                    text-transform: uppercase;
                    color: #ffffff;
                    text-decoration: none;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    padding: 11px 16px 12px 16px !important;
                }
            }

            .brand-logo {
                display: none;
            }
        }

        .dropdown ul {
            margin-left: 0 !important;
            width: 100%;
        }

        ul.link-list-block lh {
            padding-left: 15px;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}
