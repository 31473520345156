﻿@include media-breakpoint-up(lg) {
    .modal-dialog {
        max-width: 50% !important;
    }
}
.homepage-banner {
    border-radius: 0;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 2rem;
    z-index: 0;
}

.homepage-banner-content {
    position: absolute;
    top: 27%;
    left: 8%;

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
    }
}

.homepage-banner h3 {
    font-size: 4rem;
    font-family: $font-family-condensed;
    margin-bottom: -.2rem;
    font-style: italic;
    font-weight: 800;

    @include media-breakpoint-down(lg) {
        font-size: 3rem;
    }
}

.homepage-banner h4 {
    font-family: $font-family-body;
    margin-bottom: 1.2rem;
}

.homepage-banner-content-mobile {
    padding-left: 20px;
    padding-bottom: 25px;
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(239,239,239,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(239,239,239,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(239,239,239,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=0 ); /* IE6-9 */
}

.homepage-banner-content-mobile h1 {
    font-size: 3.4rem;
    font-family: $font-family-condensed;
    font-style: italic;
    font-weight: 800;
    margin-top: -10px;
    margin-bottom: 0;
}


.homepage-banner-content-mobile h4 {
    margin-top: 0px;
    font-family: $font-family-body;
    font-weight: 800;
}

    .icon-row {
        margin-bottom: 5rem;
        text-align: center;
    }

    .icon-row div:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 85%;
        border-left: 3px solid $highlight-color;
    }

    @include media-breakpoint-down(md) {
        .icon-row div:after {
            border-left: none;
        }
    }

    .icon-row div:first-child:after {
        border-left: none;
    }

    .icon-row img {
        max-height: 163px;
    }

    .icon-row h3 {
        text-transform: uppercase;
        font-size: 2.4rem;
        font-family: $font-family-condensed;
    }

    .icon-row hr {
        border-top: 3px solid $highlight-color;
        width: 50%;
    }

    .icon-row p {
    }

    .banner-row {
        color: white;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        margin-bottom: 35px;
        overflow: hidden;
    }

    .center-block {
        display: table;
        margin-left: auto;
        margin-right: auto;
    }

.find-a-distributor-block {
    padding: 104px 0px 131px 0px;
    background-color: $light-gray;
}

.find-a-distributor-block span {
    font-family: $font-family-compressed;
    font-weight: 700;
    font-size: 1.4rem;
    z-index: 999;
    text-transform: uppercase;
}
.find-a-distributor-block .span1 {
    background-color: $secondary-gray;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 26px 12px;
    text-align: center;
    width: 60%;
    min-height: 87px;
    color: $white;
}

.find-a-distributor-block .span2 {
    background-color: $highlight-color;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 26px 12px;
    min-height: 87px;
    text-align: center;
    width: 40%;
}

.find-a-distributor-block button {
    font-family: $font-family-compressed;
    font-weight: 700;
    font-size: 1.4rem;
    z-index: 999;
    text-transform: uppercase;
}

.news-block {
    @include media-breakpoint-down(md) {
        margin-top: .8rem;
    }

    .news-block hr {
        border-top: 3px solid $highlight-color !important;
    }
}