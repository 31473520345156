.brand-belt-header {
    font-size: 2rem;
    font-family: $font-family-compressed;
    font-weight: 400;
    color:#fff;

    @include media-breakpoint-down(md) {
        line-height: 3rem;
    }
}

.brand-belt-block {
    height: 250px;
    color: $white;
    background-size: cover;

    @include media-breakpoint-down(sm) {
        padding-top: 2rem;
    }

    .brand-belt-text{
        height:100%;
        p {
            @include media-breakpoint-down(lg) {
                padding-right: 0;
                padding-left: 0;
                }
        }
    }
}