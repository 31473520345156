﻿.news-sidebar {
    .newsletter-form {
        background: $secondary-gray;

        h4 {
            color: white;
        }

        a {
            clear: both;
            display: block;
        }
    }

    .category-list {
        background-color: #f2f2f2;
        border-top: #7E7E7E solid 3px;

        ul {
            line-height: 1.9rem;
        }
    }

}

.media-heading {
    font-size: 1.6rem;
    font-family: $font-family-condensed;
    font-weight: 700;
    margin-top: -5px;
    margin-bottom: 0px;
}

.media-date {
    font-family: $font-family-body;
    font-size: 13px;
    color: #575757;
    margin-bottom: 2px;
}

.news-landing {

    .filter-control {
        font-size: 1.4rem;
        font-family: $font-family-condensed;

        a {
            font-family: $font-family-body;
            font-size: 1rem;
        }
    }

    .media-heading {
        font-size: 1.6rem;
        font-family: $font-family-condensed;
        font-weight: 700;
        margin-top: -5px;
        margin-bottom: 0px;
    }

    .media-date {
        font-family: $font-family-body;
        font-size: 13px;
        color: #575757;
        margin-bottom: 2px;
        margin-top: -2px;
        display: block;
    }

    .current-tag {
        background-color: $highlight-color;
        color: $button-font-color;
        padding: 0px 5px 3px;
    }
}
