.three-column-block {
    a {
        text-decoration:none;

        &:hover {
            text-decoration:underline;
        }

    }
}
