﻿.news-block {
    hr {
        margin: 0rem;
    }

    h4 {
        margin-bottom: 0.3rem;
        margin-top: -.3rem;

        a {
            text-decoration: none;
        }
    }

    .media {
        padding-top: 1.5rem;

        &:first-child {
            padding-top: .5rem;
        }
    }

    .media-date {
        font-size: 15px;
    }


    .product-icon {
        img {
            width: 120px;
            margin-right: 1rem;

            @include media-breakpoint-down(md) {
                width: 110px;
            }
        }
    }
}
.product-landing {
    .news-block {
        h4 {
            font-size: 1.3rem;
            margin-bottom: 0.1rem;
        }

        .media-date {
            font-size: .95rem;
        }

        .media-body {
            font-size: .95rem;
        }

        .product-icon {
            img {
                width: 90px;

                @include media-breakpoint-down(lg) {
                    display:none;
                }
            }
        }
    }
}
