﻿$primary-nav-background: '/assets/images/WsHeaderBack.svg';
$secondary-nav-background: "/assets/images/WsHeaderBack2.svg";
$small-nav-background: "/assets/images/WsHeaderBack2sm.svg";
$mobile-nav-background: "/assets/images/mobilewaterheader.svg";
.brand-logo {
    svg {
        width: 200px;

        @include media-breakpoint-down(sm) {
            width: 120px;
        }
    }
}
.mobile-brand {
    img {
        float: right;
        width: 200px;
        display:none;
        @include media-breakpoint-down(md) {
            margin-right: 1rem;
            margin-top: .75rem;
        }

        @include media-breakpoint-down(sm) {
            width: 100px;
            margin-right: .5rem;
        }
    }
}

.footer {
    background-color: $highlight-color-dark;
}