﻿.promo-box-block {
    margin-bottom: $standard-block-bottom-margin;


    a {
        text-decoration: none;
    }

    .media {
        float: right;

        @include media-breakpoint-down(md) {
            float: unset;
        }
    }

    .media-body {
        height: 150px;
        background-color: $highlight-color;
        padding: 1rem;
        color: $white;

        @include media-breakpoint-down(sm) {
            height: 156px;
            font-size: 0.9rem;
        }

        .promo-cta {
            width: 100%;

            svg {
                width: 25px;
                fill: $white;
                float: right;
            }
        }
    }

    .product-icon img {
        width: 150px;

        @include media-breakpoint-down(sm) {
            width: 125px;
        }
    }
}
