﻿.action-square-block {
    margin-bottom: $standard-block-bottom-margin;

    .action-square-block-grid {
        padding: 0rem;

        @include media-breakpoint-down(sm) {
            width: 100% !important;
        }
    }

    .action-square-wrapper {
        padding: 10px;
        max-width: 317px !important;
        min-width: 290px !important;

        @include media-breakpoint-down(sm) {
            padding: 0 0 1rem 0;
            width: 100% !important;
            max-width: unset !important;
        }

        a {
            text-decoration: none;
        }

        .action-square {
            background-color: $light-gray;
            padding: 0 0 1rem 0;
            text-align: center;

            @include media-breakpoint-down(sm) {
                padding: 0px;
                margin: 0;
                list-style: none;
                -ms-box-orient: horizontal;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -moz-flex;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
            }

            &:hover {
                background-color: $highlight-color;
                color: $white;

                @include media-breakpoint-down(sm) {
                    h5{
                        color:#ffffff !important;
                    }
                }

                .action-square-icon {
                    svg {
                        fill: $white;
                    }
                }
            }

            .action-square-image {
                width: 100%;
                padding: 0px;

                @include media-breakpoint-down(sm) {
                    max-width: 150px;
                }
            }

            .action-square-text {
                padding: 3rem 1rem 1rem 1rem;
                height: 160px;

                @include media-breakpoint-down(sm) {
                    height: 120px;
                    padding: 10px;
                }

                p {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            .action-square-icon {
                height: 50px;
            }

            .action-square-icon svg {
                height: 50px;
            }

            .copy-wrapper {

                @include media-breakpoint-down(sm) {
                    text-align: left;
                    width: 90%;
                }

                h5 {
                    background-color: $highlight-color;
                    padding: 1rem;
                    color: $white;
                    margin: 0px;
                    font-weight: 400;

                    @include media-breakpoint-down(sm) {
                        background: none !important;
                        padding: 1rem;
                        color: #222;
                        margin: 0px;
                    }
                }
            }

            .centered-header {
                @include media-breakpoint-down(sm) {
                    display: inline-block;
                    display: flex;
                    justify-content: left;
                    align-items: center;
                }
            }

            .hide-text {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            .action-square-icon {
                @include media-breakpoint-down(sm) {
                    width: 10%;
                    height: inherit;
                    vertical-align: middle;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                svg {
                    fill: #c4ced1;

                    @include media-breakpoint-down(sm) {
                        width: 50px;
                        height: 50px;
                        display: inline-block;
                    }
                }
            }
        }
    }
}
