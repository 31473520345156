ul.link-list-block {
    lh {
        font-family: $font-family-compressed;
        font-size: 2rem;

        @include media-breakpoint-down(lg) {
            font-size: 1.6rem;
        }
    }
}

.link-list-block-landing-page{
    padding-bottom:20px;
}