.utility-belt-block {
    background-color: $highlight-color;
    font-size: 1.2rem;
    font-family: $font-family-compressed;
    font-weight: 400;
    height: 325px;
    color: $white;
    text-align: center;
    margin-top: 5rem;

    @include media-breakpoint-down(md) {
        height: unset;
    }

    a {
        text-decoration: none;
        color: $white;

        &:hover {
            text-decoration: none;

            .utility-belt-title {
                background-color: $white;
                color: $highlight-color;
            }
        }

        .utility-belt-item {
            padding-left: 15%;
            padding-right: 15%;
            padding-top: 2rem;
            padding-bottom: 1rem;
            color: $white;

            @include media-breakpoint-down(sm) {
                padding-top: 2rem;
            }

            img {
                height: 75px;
                margin-bottom: 2rem
            }

            .utility-belt-title {
                border: 1px solid #fff;
                font-size: 1.2rem;
                padding: 0.4rem;
                margin-bottom: 1.2rem;
            }

            .utility-belt-text {
                font-size: 1rem;

                p {
                    @include media-breakpoint-down(lg) {
                    }
                }
            }

            @include media-breakpoint-down(md) {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
    }
}
