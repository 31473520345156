.youtube-block {
    margin-bottom: 1.5rem;

    .youtube-embed {
        margin-bottom: 3rem;

        @include media-breakpoint-down(ms) {
            margin-bottom: 2rem;
        }
    }
}
