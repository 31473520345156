.events-block {
    h2 {
        margin-bottom: 1.5rem;
        display: inline-block;
        padding-right: 3rem;
    }

    .all-events-link {
        a {
            color: $highlight-color;
        }
    }

    svg {
        width: 30px;
    }

    a {
        text-decoration: none;
    }

    h3 {
        font-weight: 500;

        @include media-breakpoint-down(lg) {
            font-size: 1.2rem;
        }
    }

    .media-date {
        font-size: unset;
    }
}

