﻿.search-icon {
    width: 54px;
    height: 54px;
    display: block;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDgwIDgwIiBoZWlnaHQ9IjgwcHgiIGlkPSJJY29ucyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgODAgODAiIHdpZHRoPSI4MHB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cGF0aCBkPSJNNTkuNDQ2LDU1LjkxMUw0OS4zMzcsNDUuODAyYzEuOTIyLTIuNjQsMy4wNjktNS44OCwzLjA2OS05LjM5NmMwLTguODM3LTcuMTYzLTE2LTE2LTE2cy0xNiw3LjE2My0xNiwxNnM3LjE2MywxNiwxNiwxNiAgYzMuNTE2LDAsNi43NTYtMS4xNDcsOS4zOTYtMy4wNjlsMTAuMTA5LDEwLjEwOUw1OS40NDYsNTUuOTExeiBNMzYuNDA2LDQ3LjQwNmMtNi4wNjUsMC0xMS00LjkzNS0xMS0xMXM0LjkzNS0xMSwxMS0xMSAgczExLDQuOTM1LDExLDExUzQyLjQ3MSw0Ny40MDYsMzYuNDA2LDQ3LjQwNnoiLz48L3N2Zz4=');
}

.page-banner-section {
    margin-top: -2rem;

    @include media-breakpoint-down(md) 
         {
           margin-top:0rem;
        }
}

    .page-banner {
        background-image: url(/assets/images/banner-bg.jpg);
        background-position: center center;
        background-size: cover;
        background-color: #cecece;
    }

    .page-banner h1 {
        font-size: 3.4rem;
        text-transform: uppercase;
    }

    .page-banner p {
        font-size: 1.2rem;
        line-height: 1.9rem;
    }

    @include media-breakpoint-up(md) {
        .page-banner p {
            width: 85%;
        }

        .page-banner h1 {
            padding-top: 1.4rem;
        }
    }

    .silo-icon {
    }

    .right-sidebar {
        background-color: #E2E2E2;
    }

.product-landing {
    .product-silo-main {
        padding: 0px;


    }
}

    .product-silo-main {
        h2 {
            margin-bottom: 1.5rem;
        }

        .product-listing-container {
            padding: 0px;
        }


        .product-tile {

            .product-tile-wrapper {
                background-color: $light-gray;

                &:hover .product-tile-text {
                    background-color: $highlight-color;
                    color: $white;
                }

                a {
                    &.product-tile-text {
                        display: inline-block;
                        padding: 15px 5px;
                        width: 100%;
                        font-weight: 500;
                    }
                }
            }
        }
        /*.product-tile-wrapper:hover .product-tile-text {
        background-color: $highlight-color;
        color: $white;
    }*/
        a {
            text-decoration: none;
        }

        h3 {
            padding-top: 10px;

            @include media-breakpoint-down(lg) {
                padding-top: 0px;
                font-size: 1.5rem;
                margin-bottom: .3rem;
            }
        }


        .media {

            &.product-icon {
                margin-bottom: 1rem;

                @include media-breakpoint-down(lg) {
                    margin-bottom: 0rem;
                }

                img {
                    width: 100px;
                    margin-right: 1.1rem;

                    @include media-breakpoint-down(lg) {
                        width: 70px;
                        padding-top: 10px;
                    }
                }
            }
        }
    }

    .product-silo-grid-card {
        margin-bottom: 50px;
    }

    .product-silo-grid-card h3 {
        margin-top: -8px;
    }

    @include media-breakpoint-down(sm) {
        .product-silo-grid-card h3 {
            margin-top: 5px;
        }
    }


    .silo-thumbnail {
        width: 138px;
        height: 138px;
        display: inline;
        float: left;
        margin-right: 12px;
    }

    .main-list {
        padding-left: 0;
    }


    .media {
        padding-top: 16px;
    }

    .media-body p {
    }

    .media-thumbnail {
        padding-right: 10px;
    }

    .product-news-block hr {
        display: none;
    }