﻿.big-feature-block {
    margin-bottom: $standard-block-bottom-margin;

    @include media-breakpoint-down(sm) {
    }

    h3 {
        padding-bottom: 1.5rem;
    }

    ul {
        padding-left: 1rem;

        li {
            padding-bottom: .8rem;
        }
    }

    .big-feature-block-image {
        margin-bottom: 1rem;
        text-align: center;
    }

    .big-feature-block-copy {
        padding-left: 6rem;

        @include media-breakpoint-down(md) {
            padding-left: 4rem;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 15px;
        }
    }

    .simple-button-block {
        margin-top: 4rem;

        @include media-breakpoint-down(sm) {
        }
    }
}
