﻿.SKU-specific-page {
    table {
        &.specifications {
            min-width: unset;
        }
    }

    .product-hero .product-hero-copy {
        padding-left: 1rem;
    }

}
