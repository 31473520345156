.playVid {
    position: relative;
    display: inline-block;

    @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 100px;
    }
}

.videoType {
    display: block;
    width: 100%;
    height: auto;
}

.videoWaterMark {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    transform: translate(-50%, -50%);
    background-image: url('../../assets/images/icons/play_button.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    pointer-events: auto;
    cursor: pointer;
    z-index:15;
}

.videoImageBox{
    position:relative;
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    //overflow: hidden;
    //white-space: nowrap;
    @include media-breakpoint-down(sm) {
        /* position: relative; */
        height: 100px;
        bottom: -100px;
        /* margin-bottom: 100px; */
        overflow: hidden;
        text-overflow: ellipsis;
        z-index:10;
    }
}

.overlay h2 {
    margin: 0;
    padding: 0;
    //font-size: 1.5em;
    color: white;
    //white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
    @include media-breakpoint-down(sm) {
        max-height: 100px;
        overflow: hidden;
    }
}

.overlay p {
    margin: 0;
    padding: 0;
    //font-size: 0.9em;
    color: white;
    //overflow: hidden;
    //text-overflow: ellipsis;
    @include media-breakpoint-down(sm) {
        display:none;
    }
}

.youtube-loader-block {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
}
