﻿html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: $font-family-body;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    color: $body-color;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
        margin-bottom: 415px;
    }

    &.bodyclass {
        overflow: hidden !important;

        .togglesearch {
            z-index: 1000;
        }
    }
}

    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1280px !important;

        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
            overflow-wrap: anywhere;
        }
    }

    ::-moz-selection {
    }

    ::selection {
    }

hr {
    border-top: 2px solid $light-gray;
}

.action-square-text {
    color: #333333;
}
.action-square:hover .action-square-text {
    color: #fff;
}
/*.breadcrumbs ul {
    display: inline-flex;
}*/
.breadcrumb {
    background-color: none !important;
}
.breadcrumbs ol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: .75rem 1rem .75rem 0;
    margin-bottom: 1rem;
    list-style: none;
    background-color: none;
    border-radius: .25rem;
    

    li {
        padding-right: 7px;
        list-style-type: none;


        a {
            color: $highlight-color;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

.breadcrumbs-container {
    margin-top: 10px;
    z-index: 1000;
    position:relative;
}

.breadcrumbs {
    ol {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: .75rem 1rem .75rem 0;
        margin-bottom: 1rem;
        list-style: none;
        background-color: none;
        border-radius: .25rem;

        li {
            padding-right: 7px;
            list-style-type: none;

            @include media-breakpoint-down(sm) {
                font-size: .8rem;
            }

            a {
                color: $highlight-color;
                text-decoration: none;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }
}

.breadcrumbs-container {
    margin-top: 10px;

    @include media-breakpoint-down(sm) {
        margin-top: 50px;
    }
}

.notes {
    ul {
        padding-left: 1.5rem;

        li {
            list-style: square;

            ul {

                li {
                    list-style: circle;
                }
            }
        }
    }
}

.header-image-belt {
    h1, p {
        text-shadow: 0 0 10px black;
    }
}

.hero-banner {
    .white-text {
        h1, p {
            text-shadow: 0 0 10px black;
        }
    }
}

.full-gray-fill {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $light-gray;
    margin-bottom: $standard-block-bottom-margin;

    &.bottom-fill {
        margin-bottom: 0px;
    }
}

.home-page {
    .products-listing {
        margin-bottom: $standard-block-bottom-margin;
    }

    .full-gray-fill {
        padding-top: 2rem;
        padding-bottom: 2rem;
        background-color: $light-gray;
       
        &.bottom-fill {
            margin-bottom:0px;
        }
    }
}

a img {
    text-decoration: none;
    border: 0 none;
}

    /*.table-striped tbody tr:nth-of-type(odd) {
        background-color: $very-light-gray;
    }*/

    .newsletter-wrapper {
        background-color: $header-gray;
        min-height: 203px;
        color: $white;
    }

    .newsletter-wrapper h1 {
        color: $highlight-color;
        font-family: $font-family-condensed;
        font-weight: 800;
        font-size: 3.5rem;
        margin-bottom: 0px;
    }

    .newsletter-wrapper span {
        font-size: 1.6rem;
    }

    .newsletter-wrapper input {
        height: 63px;
        border-radius: 0;
    }

    .newsletter-wrapper button {
        height: 63px;
        border-radius: 0;
        margin-top: 8px;
    }

    .newsletter-wrapper img {
        height: 54px;
        width: 54px;
    }

    .newsletter-wrapper h1 {
        font-size: 3rem;
        margin-bottom: 0px;
        margin-top: 20px;
    }

    .newsletter-wrapper span {
        font-size: 1.2rem;
    }


.footer {
    position: static;
    /*bottom: 0;*/
    width: 100%;
    /* fixed height */
    min-height: 300px;
    color: $light-gray;
    font-size: 21px;
    font-family: $font-family-body;
    margin-top: 4em;

    @include media-breakpoint-down(md) {
        //min-height: 415px;
    }

    a {
        color: $light-gray;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
    }

    a:hover {
        color: $white;
        transition: all 0.3s ease-in-out;
        text-decoration: underline;
    }

    span {
        display: block;
    }

    ul {
        list-style: none;
        padding-left: 0px;
        display: inline-block;
        vertical-align: top;


        @include media-breakpoint-down(sm) {
            display: block;
        }

        li {
            padding-left: 1em;

            @include media-breakpoint-down(sm) {
                padding-left: 0;
            }
        }
    }

    .footer-menu-column {
        ul {
            li:first-child {
                a {
                    font-family: $font-family-condensed;
                    font-weight: 600;
                    font-size: 1.15rem;
                }
            }
        }
    }
}

    .recall {
        text-align: right;

        @include media-breakpoint-down(md) {
            text-align: left;
        }
    }

    .social-media-icon {
        display: inline-block;
        margin: 0 10px 0 0;

        svg {
            fill: $white;
        }
    }

    .social-media-icon a {
        display: inline-block;
    }

.social-media-icon:hover svg {
    background-color: $button-hover-color;
}

.social-media-icon svg {
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 5px;
}



.ffs-green {
    color: $button-font-color;
    background-position: right;
    background-repeat: no-repeat;
    background: rgb(62,126,142);
    background: linear-gradient(135deg, $button-color 0%, $button-color 35%, $button-hover-color 35%);
    background-size: 200% 100%;
    box-shadow: inset 0 0 0 0 #D80286;
    transition: all 0.3s ease;

    &:hover {
        background-position: right bottom;
        color: $button-font-color;
        transition: all 0.3s ease;
    }
}

.fe-btn {
    border:none;
    border-radius: 0;
    display: inline-block;
    padding: 8px 6px 8px 17px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none !important;
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    font-family: $font-family-body;
    font-weight: 700;
    font-size: 1.1rem;

    @include media-breakpoint-down(sm) {
        white-space: normal;
    }

    .btn-text {
        padding-right: 62px;

        @include media-breakpoint-down(xs) {
            padding-right: 32px !important;
        }
    }

    svg {
        fill: $white;
        width: 20px;
    }
}

.ffs-btn {
    border: none;
    border-radius: 0;
    transition: background-color 0.3s ease-out;
    font-family: $font-family-compressed;
    font-weight: 700;
    font-size: 1.4rem;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        margin-top: 16px;
    }
}

    .ffs-btn-lg {
        padding: .45rem 1rem;
    }

    .ffs-btn:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }

    .ffs-green-hr {
        border-top: 3px solid $highlight-color;
    }

    .water-systems-btn {

    }

    .iframe-container {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;

        iframe {
            vborder: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .form-control:focus {
        color: #495057;
        border-color: $highlight-color;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .input-group-lg > .input-group-append > .btn {
        border-radius: 0;
    }

    .search-form {
        .text-input {
            border-radius: 0;
        }

        .btn {
            border-radius: 0;
            background-color: $highlight-color;
            font-family: $font-family-compressed;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

#cookie-consent {
    border-radius: 0;
    border-color: transparent;
    background-color: #575757;
    color: #fff;
    margin-bottom: 0.4rem;

    @include media-breakpoint-down(md) {
        font-size: .8rem;
        margin-top: 60px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 45px;
    }

    a {
        color: #fff;
        font-weight: bold;

        &:hover {
            text-decoration: none;
        }
    }

    #cookie-consent-close {
        line-height: .8;
    }

    .close {

        &:hover {
            color: #8a8a8a;
        }
    }
}


    .service-banner {
        background-color:#c3c3c3;
        border-color: $highlight-color-light;
        border-radius: 0;
        border:none;

        @include media-breakpoint-down(md) {
            font-size: .8rem;
        }

        a:hover {
            text-decoration: none;
        }
    }

    .ValidationMessage {
        color: red;
    }

    .black-text {
        color: #222;
    }

    .white-text {
        color: #fff;
    }


.water-systems {
    .row-bottom-border {
        border-top: 1px solid #bbb;
        padding-top: 1.5rem;
        padding-bottom: .8rem;

        @include media-breakpoint-down(md) {
            padding-top: 1rem;
            padding-bottom: .4rem;
        }
    }

    .cta-link {
        font-weight: 500;
    }

    .news-block {
        margin-bottom: $standard-block-bottom-margin;

        .media:first-child {
            padding-top: 1.5rem;
        }

        .product-icon {
            img {
                width: 180px;

                @include media-breakpoint-down(md) {
                    width: 120px;
                }
            }
        }

        h2 {
            display: inline-block;
            padding-right: 3rem;
        }

        .all-news-link {
            a {
                color: $highlight-color;
            }
        }

        a {
            text-decoration: none;

            h3 {
                font-weight: 500;

                @include media-breakpoint-down(md) {
                    font-size: 1.2rem;
                }
            }

            span {
                font-weight: 400;
            }

            .media-date {
                font-size: unset;
            }
        }
    }

    .events-block {
        .all-events-link {
            a {
                color: $highlight-color;
            }
        }
     }
}
.water-systems-search-page {
    .tab-content hr {
    border-top: 1px solid #b9b9b9;
    }
}

.raw-html-block.mt-5 {
    margin-top: 0px !important;
}

.branchInfoBox {
    display: block;
    position: relative;
    font-size: 1.3em;

    ul {
        color: #656565;
        list-style: none;
        padding: 0;
    }
}

.branchEmployeesBox {
    padding: 1em 15px;
    background-color: #e9e6e6;
    background: linear-gradient(180deg, rgba(233, 230, 230, 1) 0%, rgba(255, 255, 255, 1) 100%);

    a {
        color: $highlight-color;
        text-decoration: none;
    }

    h2 {
        margin-bottom: 20px;
    }
}

.salesperson {
    h2 {
        margin-bottom: 0;
        padding-top: 1em;
    }

    h3 {
        margin-bottom: 0px;
    }

    p {
        color: #7f7f7f;
        padding-bottom: 5px;
        border-bottom: 1px solid #d1d1d1;
        font-size: 1.2em;
        margin-bottom: 7px;
    }

    ul {
        color: #7f7f7f;
        list-style: none;
        padding: 0;
        margin-bottom: 40px;
    }
}

.product-content-block .otherBranchesBox h3 {
    margin-top: 20px !important;
}

.otherBranchesBox {
    margin-top: 1em;
    background: linear-gradient(-90deg, #f7f7f7 0%, #fff 10%);

    &.branch-block {
        background: linear-gradient(180deg, #f7f7f7 0%, #fff 10%);
        padding: 15px;
    }

    h3 {
        border-bottom: 1px solid #e9e6e6;
        display: block;
        margin-bottom: 8px;
        margin-top: 20px;
        padding-bottom: 3px;
    }

    h4 {
        padding-left: .5em;
        font-size: 1.2em;
    }

    a {
        color: $highlight-color;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    .col-3 {
        @include media-breakpoint-down(xs) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}

.company-page .otherBranchesBox {
    background: linear-gradient(180deg, #e9e6e6 0%, #fff 100%);
    padding: 1em 1em;
}

.simple-rte-block{
    img{
        max-width:100%;
        height:auto;
    }
}

.company-page {
    a {
        //color: $highlight-color;
        text-decoration: none;
    }

    .companyPageBrandLogo {
        display: inline-block;
        position: relative;
        max-width: 80px;
        float: right;
        text-align: right;
        padding-top: 10px;

        img {
            width: 100%;
        }
    }
}

.companySearchBarBox {
    padding: 1em;
    background-color: #e9e6e6;
    margin: 2em 0;
    position: relative;
    background: linear-gradient(180deg, rgba(233, 230, 230, 1) 0%, rgba(255, 255, 255, 1) 100%);

    form{
        margin-bottom:0;
    }

    input {
        height: calc(1.5em + 1rem + 4px);
    }

    .companyPageSearchButton {
        position: absolute;
        right: 1em;
        bottom: 1em;
    }
}


.distributor-card {
    a {
        color: $highlight-color;
        text-decoration: none;
    }

    .branch-lookup-moredetail {
        background-color: $highlight-color;
        color: #ffffff;
        padding: .5em 1em;
        display: block;
        text-align: center;
        font-weight:600;
    }

    .branch-lookup-moredetail:hover {
        background-color: $highlight-color-light;
    }
}

.headwater {

    article.post.mb-4 {
        border-bottom: 2px solid $light-gray;
        margin-bottom: 2rem !important;

        .row {
            margin-bottom: 2rem !important;
        }
    }
}




article {
    a {
        color: $highlight-color;
        text-decoration: none;
    }

    a:hover {
        color: $highlight-color-light;
        text-decoration: none;
    }

    .row {

        @include media-breakpoint-down(sm) {
            padding: 1em 0;
            margin: 0;
        }
    }
}

.events-landing-page {
    table tr {
        tr:hover {
            background-color: transparent !important;
        }

        padding: 1em 1em 1em 0;
        //box-shadow: #e1e1e1 0px 3px 20px 3px;
        border: 1px solid #dddddd;

        @include media-breakpoint-down(sm) {
            padding: 1em 0;
            margin: 0;
        }

        td {
            vertical-align: middle;
            padding: 1em;
            border-top: none;
            //text-align: right;
            border-right: 1px solid #f1f1f1;
        }

        h4 {
            font-size: 2rem;
            font-family: proxima-nova-extra-condensed, "Arial Narrow", Arial, sans-serif;
            font-weight: 700;
            margin-top: -5px;
            margin-bottom: 0px;
            //display: inline-block;
            //float: left;
            display: block;
            width: 100%;
            //text-align: left;
        }

        .date-column h4 {
            font-size: 1.3em;
        }

        h5 {
            display: inline-block;
            margin: 0 1em 0 0;
        }

        a {
            float: none !important;
            margin: 0 !important;
        }

        .fe-btn {
            float: right !important;
        }

        a span {
            color: #ffffff;
            text-decoration: none;
        }

        a:hover {
            color: $highlight-color-light;
            text-decoration: none;
        }

        .date-column {
            vertical-align: middle;
            color: grey;
        }

        .d-sm-block {
            border-right: none;
            min-width: 180px;
        }
    }

    @include media-breakpoint-down(sm) {
        .fe-btn .btn-text {
            padding-right: 56px;
        }
    }
}

.events-block {
    padding-top: 1em;
    padding-bottom: 1em;
    color: #575757;

    h2 {
        a {
            color: #575757;
        }
    }

    .row-bottom-border {
        padding: 1em 0;
        border-top: 1px solid #cdcdcd;

        h3 {
            a {
                font-weight: 700;
            }

            span {
                font-size: 23px;
                font-weight: 600;
            }

            margin-bottom: 0;
        }

        @include media-breakpoint-down(lg) {
            h3 {
                span {
                    font-size: 19px;
                }
            }
        }

        .cta-link {
            a {
                font-size: 1em;
                color: $highlight-color-light;
            }

            polygon {
                font-size: .5em;
                fill: $highlight-color-light;
            }
        }

        svg {
            width: 20px;
        }
    }
}

.news-block {
    padding-top: 1em;
    padding-bottom: 1em;

    h2 {
        margin-bottom: 1.5rem;
        display: inline-block;
        padding-right: 3rem;

        a {
            color: #575757;
        }
    }

    h3 {
        .media-date {
            font-size: 23px;
        }
    }

    @include media-breakpoint-down(lg) {
        h3 {
            font-size: 1.2em;

            .media-date {
                font-size: 19px;
            }
        }
    }
}

.notes {
    margin-top:0em !important;
    p {
        margin-top: 0 !important;
        margin-bottom: 1em;
        border-top: 1px solid #e7e7e7;
        border-bottom: 1px solid #e7e7e7;
        padding: 1em 0;
        font-style: italic;
    }
}

.free-form-page {
    max-width: 1280px !important;
    margin: 0 auto;
}

.classifieds-item {
    a {
        color: $highlight-color;
        text-decoration: none;
    }

    a:hover {
        color: $highlight-color-light;
    }
}

.classifiedsPreviewDescText {
    margin-bottom: 3em;
}

.classifiedsMoreInfo {
    display: block;
    text-align: center;
    background-color: $highlight-color;
    color: #fff;
    padding: .5em 1em;
    position: absolute;
    bottom: 0;

    @include media-breakpoint-down(sm) {
        position: relative;
        width: 100%;
    }
}

.classifiedsMoreInfo:hover {
    background-color: $button-hover-color;
    color: #fff;
}

.classifieds-landing-page, .events-landing-page {
    .col-sm-7 {
        max-width: 100% !important;
        flex: none;
    }

    .caption {
        margin-bottom: 3em;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0em;
        }
    }
}

.news-landing, .events-landing-page {
    .filter-control {
        color: #a5a5a5;
    }

    .category-list {
        height: 100%;
        background: linear-gradient(180deg, #e9e6e6 0%, #fff 100%);

        li {
            margin-bottom: .5em;
            border-bottom: 1px solid #cbcbcb;
        }
    }
}

.allOppsSideBar {
    padding: 1em;
    background: linear-gradient(180deg, #e9e6e6 0%, #fff 100%);

    .row {
        border-top: 1px solid #c3c3c3;
        padding: .5em 0 0 0;
    }
}

.company-contact {
    color: #5b5b5b;
}

.contactInfoBox {
    border-left: solid 2px $highlight-color;
    margin-left: 1em;
    li{
        list-style:none;
    }
}

//HW Employee Search
.employeeSearchPage {
    .salesperson {
        margin-bottom: 1em;
    }
}

.suppliersListTR {
    display: inline-block;
    padding-right: 10px !important;

    a {
        padding: 1em;
        display: block;
        border: 1px solid #dee2e6;
    }

    a:hover {
        color: #ffffff;
        background-color: $highlight-color;
    }
}

.suppliersListSidebar {
    padding: 1em;
    background: linear-gradient(180deg, #e9e6e6 0%, #fff 100%);

    li {
        border-top: 1px solid #c3c3c3;
        padding: .5em 0 0 0;
    }
}

.HwCompaniesBranch #wrapper {
    height: auto;
}

.hundredWidth{
    width: 100%;
}

.displayNone {
    display: none;
}
