﻿.header-image-belt {
    padding-top: 74px;
    margin-top: -68px;
    margin-bottom: $standard-block-bottom-margin;
    min-height: 400px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    color: #fff;

    .text-container-wrapper {


        p {
            padding-right: 25%;
            font-size: 1.3rem;
            @include media-breakpoint-down(md) {
                padding-right: 0px;
            }
        }
    }

    .fe-btn {
        position: relative;
        bottom: 20px;
        margin-top: 4rem;

        @include media-breakpoint-down(sm) {
            margin-top: 2rem;
        }
    }
}
