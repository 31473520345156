.stock-block {
    background-color: #005daa;
    color: white;
    padding: 15px;
    font-family: Arial, sans-serif;
    width: 300px;
    text-transform: uppercase;
}

.stock-block .company-name {
    margin-bottom: 1em;
}

.stock-block .price h3 {
    font-size: 2em !important;
    font-weight: 600 !important;
    margin-bottom: 0 !important;
}

.stock-block .info {
    margin-top: 1em;
}

.stock-block .pricing-delay {
    font-size: .6em;
    margin-top: 1em;
}

.price-change h3 {
    font-weight: 600 !important;
    font-size: 2em !important;
}

.priceUp {
    color: #27db27;
}

.priceDown {
    color: #ff8585;
}
