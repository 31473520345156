.video-multi-block {
    margin-bottom: $standard-block-bottom-margin;

    h2 {
        margin-bottom: 1.5rem;
    }

    .row {
        margin-bottom: -1.5rem;
    }

    .video-main {
        @include media-breakpoint-down(sm) {
            margin-bottom: -.45rem;
        }
    }

    .video-secondary-wrap {
        padding-right: 0px;
    }

    .video-secondary {
        padding-left: 0px;

        &:first-child {
            margin-bottom: -.45rem;
        }
    }
}
