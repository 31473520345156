﻿
    .pim-table {
        width: auto;

        th {
            white-space: nowrap;
            padding: 5px;
            font-family: $font-family-condensed;
            font-size: 1.125rem;
            font-weight: 600;
        }

        td {
            padding: 5px;

            &:first-child {
                white-space: nowrap;
            }

            p:last-child {
                margin-bottom: 0px;
            }
        }
    }

    .notes {
        font-size: small;
        margin-top: -14px;
        color: #313131;
    }

    .sku-highlight-text {
        font-weight: bold;
    }

table.pim-table tbody tr.sku-highlight-row:hover {
    background-color: $light-gray !important;
}

