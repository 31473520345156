﻿//predictive search
#ui-id-1 {
    position: absolute !important;
    z-index: 9999;
    background-color: $white;
    border: 4px solid $navbar-dropdown-color;
    width: 560px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    list-style: none;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);

    li {
        border-bottom: 1px solid $navbar-dropdown-color;

        &:last-child {
            border-bottom: none;
        }
    }

    .result-title {
        font-size: 1rem;
        font-family: $font-family-condensed;
        font-weight: 800;
    }

    .media {
        padding-top: 0rem;
        margin-top: -1px;

        img {
            width: 80px;
            margin-top: 0.3rem;
            margin-right: 1rem;
        }

        a {
            text-decoration: none;
            display: -webkit-box;
            padding-top: 16px;
            padding-bottom: 1rem;

            &:hover {
                text-decoration: underline;
            }
        }

        .media-body {
            p {
                margin-bottom: 0rem;
            }
        }
    }
}

#ui-id-4 {
    border-bottom: none !important;
}

.ui-helper-hidden-accessible {
    display: none;
}

