﻿h1 {
    font-family: $font-family-compressed;
    font-size: 3.5rem;
    font-weight: 400;
}

h2 {
    font-family: $font-family-condensed;
    font-weight: 600;
}

h3 {
    font-family: $font-family-condensed;
    font-weight: 600;
    font-size: 1.5rem;
}

h4 {
    font-family: $font-family-condensed;
    font-weight: 600;
    font-size: 1.25rem
}

h5 {
    line-height: 1.5;
}

a {
    color: $highlight-color;
    text-decoration: none;
}

a:hover {
    color: $highlight-color-light;
    text-decoration: none;
    transition: none;
    transition: none;
}
