﻿.document-center {

    h3 {
        color: $header-gray;
    }

    .product-content-block-image {
        img {
            float: right;
        }

        @media (max-width: 750px) {
            img {
                float: none;
                max-height: none !important;
                width: 100%;
            }
        }
    }

    .product-filter-search-container {
        margin: 0 0 40px 0;

        @media (min-width: 976px) {
            margin-right: -15px;
        }

        .hide {
            display: none;
        }

        .show {
            display: block;
        }

        .dropdown-container {
            margin-left: -15px;

            @media (max-width: 992px) {
                margin-left: 0px;
            }
        }

        .dropdown-title {
            font-size: 14px;
            color: $header-gray;
        }

        .dropdown {
            .dropdown-toggle {
                width: 100%;
                text-align: left;
                border-radius: 0;
                border-color: $light-gray;
                background-color: white;
                color: $header-gray;
                height: 48px;
                overflow: hidden;

                &:focus {
                    box-shadow: none;
                }

                &::after {
                    position: absolute;
                    right: 17px;
                    top: 48%;
                    color: $header-gray
                }

                .dropdown-background {
                    background-color: $light-gray;
                    width: 48px;
                    height: 48px;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                }
            }

            .dropdown-menu {
                min-width: 312px;
                border-radius: 0;
                background-color: $very-light-gray;
                padding: 12px;
                box-shadow: 3px 3px 5px -2px $navbar-color;
                z-index: 3000;

                .dropdown-product-catelog-link {
                    cursor: pointer;
                    text-decoration: none;
                    padding: 0 5px;
                    display: block;

                    &.select-label {
                        color: gray;
                    }

                    &:hover {
                        background-color: $highlight-color;
                        color: $button-font-color;
                    }
                }

                .dropdown-product-category-link {
                    color: $header-gray;
                    font-weight: bold;
                }

                .dropdown-product-sub-category-link {
                    color: $header-gray;
                    margin-left: 12px;
                    padding-left: .4rem;
                    font-weight: bold;
                }

                .dropdown-product-page-link {
                    cursor: pointer;
                    margin-left: 36px;
                    text-decoration: none;
                    display: block;
                    padding: 0 5px;

                    &:hover {
                        background-color: $highlight-color;
                        color: $button-font-color;
                    }

                    &.grid {
                        margin-left: 0px;
                    }
                }
            }

            &.dropdown-product {
                .dropdown-menu {
                    max-height: 400px;
                    overflow: auto;
                }
            }
        }

        .or-text {
            color: $secondary-gray;
            padding: 33px 0 0 0;

            .or-line-left {
                letter-spacing: -3px;
                padding: 0 10px 0 0;
            }

            .or-line-right {
                letter-spacing: -3px;
                padding: 0 0 0 10px;
            }
        }

        .search-container {
            .document-center-search-form {
                .btn {
                    width: 100%;
                }

                .search-btn {
                    padding: 0;

                    @media (max-width: 976px) {
                        padding-right: 15px;
                    }
                }

                .search-field {
                    padding-right: 0;
                }
            }
        }
    }

    .product-tabs {
        .nav-tabs {
            .nav-item {
                cursor: pointer;
                margin-bottom: 0px;
            }
        }

        .tab-content {
            a {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .video {
            cursor: pointer;

            img {
                object-fit: cover;
                width: 100%;
            }

            .product-modal {
                iframe {
                    width: 100%;
                    min-height: 500px;
                }
            }
        }
    }

    .product-icon {

        @media (max-width: 976px) {
            padding-bottom: 35px;
        }

        .media-body {
            display: flex;
            align-items: center;
            align-self: center;

            @media (max-width: 976px) {
                flex-direction: column;
                align-items: start;
            }

            a {
                text-decoration: none;
            }

            span {
                color: $header-gray;
                padding: 0 15px;

                @media (max-width: 976px) {
                    padding: 0;
                }
            }
        }
    }
}

.modal-backdrop {
    background-color: white;

    &.show {
        opacity: .9;
    }
}

.water-systems {
    .document-center {
        .tab-content {
            border-top: 0.2rem solid #E2E2E2;
            padding-top: 3rem;
        }
    }
}