.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}
#main {
    width: 100%;

    .toggle-container {
        margin-top: -3px;
    }

    a.dt-toggle-nav, a.toggle-nav {
        text-decoration: none;
        font-size: 0.8rem;
        margin-bottom: 7px;
        display: inline-block;

        svg {
            margin-right: 0.2rem;
            margin-top: -.2rem;
        }
    }
}


#wrapper {
    width: unset;

    @include media-breakpoint-down(md) {
        width: 100%;
        margin-left: 0;
        margin-bottom: 0;
    }
}

#sidebar {
    max-width: 360px;
    min-width: 360px;
    margin-right: 1.5rem;
    padding-left: 0px;
    height: unset;
    background-color: transparent;
    overflow: hidden;
    z-index: 3;


    @include media-breakpoint-down(lg) {
        max-width: 250px;
        min-width: 250px;
    }

    @include media-breakpoint-down(md) {
        margin-left: -250px;
        margin-right: 0px;
    }

    a {
        text-decoration: none;
        
        &:hover {
            text-decoration: underline;
        }

    }

    ul {
        li {
            a {
                white-space: normal;
                word-wrap: break-word;
                display: inline-block;
                width: 100%;
            }
        }

        ul {

            lh {
                line-height: 1.4;
                cursor: default;
                display: block;
                margin-top: 7px;
            }

            li {
            }
        }
    }

    &.active {
        @include media-breakpoint-down(md) {
            margin-left: 0px;
        }

        .dropdown-toggle::after {
            top: auto;
            bottom: 10px;
            right: 50%;
            -webkit-transform: translateX(50%);
            -ms-transform: translateX(50%);
            transform: translateX(50%);
        }
    }

    .active {
        font-family: $font-family-condensed;
        font-weight: 700;
        color: $highlight-color;
    }

    .collapse {
        -webkit-transition-timing-function: ease;
        -o-transition-timing-function: ease;
        transition-timing-function: ease;
    }

    .dropdown-toggle::after {
        display: none;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }

    .list-group {
        min-width: 400px;
        background-color: #333;
        min-height: 100vh;
    }

    ul { /*CSS for Simple Tree Menu*/
        padding-left: 0px;
        margin: 0;
        padding: 0;
        background-repeat: no-repeat;
        cursor: pointer !important;

        &.treeview {

            li { /*Style for LI elements in general (excludes an LI that contains sub lists)*/
                background-repeat: no-repeat;
                list-style-type: none;
                list-style-image: none;
                line-height: 17px;

                &.submenu { /* Style for LI that contains sub lists (other ULs). */
                    background-position: 5px 10px;
                    cursor: pointer !important;
                    margin-bottom: .5rem;
                    line-height: 1.4rem;

                    @include media-breakpoint-down(lg) {
                        margin-bottom: .3rem;
                    }

                    ul { /*Style for ULs that are children of LIs (submenu) */
                        display: none; /*Hide them by default. Don't delete. */
                        li { /*Style for LIs of ULs that are children of LIs (submenu) */
                            cursor: default;
                            padding-left: 31px;
                            list-style-type: none;

                            div:hover {
                                background-color: transparent;
                                cursor: auto;
                            }
                        }

                        .treeview .submenu ul li a.hover { /*Style for LIs of ULs that are children of LIs (submenu) */
                            cursor: default;
                            padding-left: 10px;
                            text-decoration: underline;
                        }
                    }
                }

                .submenu > ul > li > a {
                    display: block;
                }

                div {
                    width: 100%;
                }
            }
        }
    }
}


#expand-menu-col {
    max-width: 70px;
}

a[data-toggle="collapse"] {
    position: relative;
}


#productNav {
    padding: 0;
    margin-bottom: 1.1rem;
}

.silo {
    font-family: $font-family-body;
    font-weight: 700;
    font-size: 21px;
    border-top: rgba( $highlight-color, .4 ) 2px solid;


    &:first-child {
        border-top: none;
    }

    &:last-child {
        border-bottom: $light-gray 2px solid;

        &.silo-active {
            border-bottom: none;
        }
    }

    &.silo-active {
        margin-bottom: 1rem;
    }

    .silo-link-div a {
        font-weight: 500;
        border-bottom: $highlight-color 2px solid;
    }

    a {
        padding: 10px 8px 10px 0px;
    }

    .silo-active {
        margin-bottom: 1rem;
    }

    .silo-link-div-active {
        margin-bottom: 7px;
    }

    @include media-breakpoint-down(lg) {
        font-size: 18px;
    }
}


.product-category {
    font-family: $font-family-condensed;
    font-weight: 700;
    font-size: 19px;

    @include media-breakpoint-down(lg) {
        font-size: 15px;
    }

    a {
        padding: 5px 5px 5px 9px;
    }
}

.product-series-wrapper {
    color: $header-gray;
    padding-left: .4rem;
    margin-left: 20px;
    padding-bottom: .1rem;
    margin-bottom: .3rem;
    text-transform: uppercase;
    border-bottom: 2px solid $light-gray;
}

.product-series {
    font-family: $font-family-oldCompressed;
    font-weight: 700;
    font-size: 16px;
    color: $header-gray;

    @include media-breakpoint-down(lg) {
        font-size: 14px;
    }

    a {
        padding: 5px;
    }
}

.product-tertiary-wrapper {
    color: $header-gray;
    padding-left: 1.75rem;
    padding-bottom: .1rem;
    width: 93% !important;
}

.product-tertiary {
    font-family: $font-family-condensed;
    font-weight: 700;
    font-style: italic;
    font-size: 16px;
    color: $header-gray;

    @include media-breakpoint-down(lg) {
        font-size: 14px;
    }

    a {
        padding: 5px;
    }
}

.product-page {
    font-family: $font-family-body;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;


    @include media-breakpoint-down(lg) {
        font-size: 14px;
    }

    .link-wrapper {
        width: 100% !important;
    }
}

    .clicker-div {
        background-image: url(/assets/images/ToggleNormWS.svg);
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        float: left;
        background-size: 100%;
        margin-top: .4rem;
        margin-right: -.3em;
    }


.link-wrapper {
    width: 93% !important;
    display:inline-flex;

    @include media-breakpoint-down(lg) {
        width: 91% !important;
    }

}

*html .treeview li.submenu { /* Style for LI that contains sub lists (other ULs). */
    margin-right: 5px;
    /*cursor: hand !important;*/
    cursor: pointer !important;
}

*:first-child + html .treeview li.submenu {
    background-position: 0px 7px;
}

*html #IETableFix td {
    height: 200px;
}

.productLink a {
    display: block;
}

.collapse.show {
    visibility: visible;
}