﻿.feature-carousel-block {
    background-color: $light-gray;
    margin-bottom: $standard-block-bottom-margin;
    min-height: 350px;

    .feature-carousel-slide {
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @include media-breakpoint-down(sm) {
    }

    h3 {
        padding-bottom: 1.5rem;
    }

    ul {
        padding-left: 1rem;

        li {
            padding-bottom: .8rem;
        }
    }

    .feature-carousel-slide-image {
    }

    .feature-carousel-slide-copy {
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include media-breakpoint-down(md) {
            /*padding-left: 4rem;*/
        }

        @include media-breakpoint-down(sm) {
            padding-left: 15px;
        }
    }

    .simple-button-block {
        margin-top: 2rem;

        @include media-breakpoint-down(sm) {
        }
    }
}
.full-gray-fill {
    .feature-carousel-block {
        margin-bottom:0rem;
    }
}

    .carousel-control-prev, .carousel-control-next {
        width: 10%;
    }

    .carousel-control-next-icon, .carousel-control-prev-icon {
        background: none;
        width: 50px;
        height: 50px;
    }

    .carousel-control-prev, .carousel-control-prev, .carousel-control-next, .carousel-control-next {
        color: #fff;
        text-decoration: none;
        outline: 0;
        opacity: 1;

        svg {
            fill: #c4ced1;
        }
    }

    .carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
        color: #fff;
        text-decoration: none;
        outline: 0;
        opacity: inherit;

        svg {
            fill: #c4ced1;
        }
    }