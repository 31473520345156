﻿#wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}


#main {

    height: 100%;
    position: relative;

    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

#sidebar {
    width: 336px;
    height: 100%;
    top: 0;
    z-index: 0;
    background: $very-light-gray;

    @include media-breakpoint-down(md) {
        /*position: absolute;*/
    }
}

#wrapper.display-nav #sidebar,
#wrapper.display-nav #main {
    -webkit-transform: translateX(250px);
    -moz-transform: translateX(250px);
    -ms-transform: translateX(250px);
    -o-transform: translateX(250px);
    transform: translateX(250px);
}
#wrapper.display-nav #sidebar{
    margin-right:15px;
}
#wrapper.toggle-display-nav #sidebar {
    display: none;

    @include media-breakpoint-down(md) {
       display:block;
    }
}