.background-header {
    font-size: 4rem;
    font-family: $font-family-compressed;
    font-weight: 700;
    line-height: 4rem;

    @include media-breakpoint-down(md) {
        font-size: 3rem;
        line-height: 3rem;
    }
}

.background-block {
    padding: 1rem 3rem;

    @include media-breakpoint-down(md) {
        padding: 1.4rem;
    }

    p {
        font-size: 1.4rem;
        padding-right: 3rem;
        padding-left: 3rem;

        @include media-breakpoint-down(lg) {
            padding-right: 0;
            padding-left: 0;
        }

        @include media-breakpoint-down(md) {
            font-size: 1.2rem;
        }
    }
}