﻿@media print {
    #cookie-consent {
        display:none;
    }

    .alert.service-banner {
        display:none;
    }

    .footer {
        display: none;
    }

    .SKU-specific-page button {
        display:none;
    }

    .utility-belt-block {
        display: none;
    }

    .SKU-specific-page .SKU-specifications td:nth-child(1) {
        width: 30%;
    }
}
