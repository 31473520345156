﻿.case-study-landing-page {
    .card-wrapper {
        span {
            display: inline-block;
            font-size: 2rem;
            font-family: $font-family-compressed;

        }
    }
}
.case-study-page {
    h3 {
        font-family: $font-family-compressed;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 2.4rem;
        margin-top: -1.5rem;

        @include media-breakpoint-down(sm) {
            text-align: left;
        }
    }

    .right {
        border-left: 3px solid black;
        padding-left: 3rem;

        @include media-breakpoint-down(sm) {
            text-align: left;
            margin-left: 25px;
            padding-left: 2.1rem;
        }
    }

    .right:before {
        content: url('https://embed.widencdn.net/img/fele/z8txbbsn3s/x55px/ffs-case-study-marker.jpg');
        position: absolute;
        left: -14px;
        top: -28px;

        @include media-breakpoint-down(sm) {
            left: -13px;
            top: -40px;
        }
    }

    .green-marker-row {
        height: 52px;
    }

    .green-marker:before {
        content: url('https://embed.widencdn.net/img/fele/ime7spa96c/x85px/ffs-case-study-marker-green.png');
        position: absolute;
        left: -37px;
        top: 8px;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .summary-row {
        background-color: $light-gray;
        padding: 60px 239px 30px 239px;

        @include media-breakpoint-down(md) {
            padding: 0px;
        }

        h3 {
            margin-top: 0px;
        }
    }

    .header-column .row {
        @include media-breakpoint-down(md) {
            margin-bottom: 0px !important;
        }
    }

    .header-column .row h3 {
        @include media-breakpoint-only(sm) {
            margin-bottom: -7px;
        }

        @include media-breakpoint-only(xs) {
            margin-bottom: -7px;
            margin-left: 47px;
        }
    }
}