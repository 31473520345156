﻿
.block {
    .simple-image-block {

        h3 {
            clear: both;
        }

        &.image-full-width-text-left {
            text-align: left;
        }

        &.image-left-text-left {
            text-align: left;
            float: left;
            padding-right: 2rem;
        }

        &.image-right-text-left {
            text-align: left;
            float: right;
            padding-left: 1.75rem;
        }

        &.image-full-text-center {
            text-align: center;
        }
    }
}
