﻿.home-page {
    .search-box-block {
        min-height: 371px;
        /*background: url(https://embed.widencdn.net/img/fele/cubqmboapt/1600x593px@1x/Web-Banner-FMS-Duo-2020.jpg?q=80&x.template=y&crop=false);*/
        background-position: center;
        padding: 8rem 3rem;

        @include media-breakpoint-down(md) {
            min-height: 217px;
            padding: 3rem 1rem;
        }
    }
}

.product-landing {
    .search-box-block {
        margin-bottom: 2rem;
    }

    hr {
        display: none;
    }
}
