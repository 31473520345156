﻿.product-content-block, .product-content-block-center {
    margin-bottom: 2rem;
    h3 {
        margin-top: -0.5rem;
    }
}

.product-content-block-center {
    .product-content-img {
        margin-bottom: 1rem;
    }
}

.product-content-block-copy {
    .product-icon img {
        width: 100px;
    }
}


.product-content-block-image img {
    @include media-breakpoint-down(sm) {
        margin-bottom: 2rem;
    }
}

.product-content-block-copy {
    a {
        padding {
            text-decoration: underline;
        }
    }

    table {
        thead {
            td, th {
                color: #fff;
                background-color: #343a40;
                border-color: #454d55;
                padding: 5px 5px 5px 10px;
                font-family: $font-family-condensed;
                font-size: 1.125rem;
                font-weight: 600;
                border: 1px solid #dee2e6;
            }
        }

        tbody {
            tr:nth-of-type(odd) {
                background-color: $very-light-gray;
            }

            td {
                padding: 5px 5px 5px 10px;
                border: 1px solid #dee2e6;
            }
        }
    }

    &.white-text {
        h3, h4, p {
            color: $white;

            @include media-breakpoint-down(md) {
                color: $body-color;
            }
        }
    }
}

#order-info {
    .product-content-block
        {
        margin-bottom: 1rem !important;
        .product-content-block-copy {
            margin-top: 2rem;
        }
    }
}

.home-page {
    .product-content-block, .product-content-block-center {
        a {
            text-decoration: none;
        }
        .product-content-block-copy {

            &.white-text {
                h3, h4, p {
                    @include media-breakpoint-down(md) {
                        color: $body-color;
                    }
                }
            }
        }
    }
}