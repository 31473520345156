.case-study-block {
    h2 {
        margin-bottom: 1.5rem;
    }

    margin-bottom: $standard-block-bottom-margin;

    .row {
        margin-bottom: -1.5rem;
    }

    .video-main {
        padding-right: 15px;
    }

    .video-secondary-wrap {
        padding-left: 0px;
    }

    .secondary-col {
        background-color: $highlight-color;
        color: $white;
        text-align: center;
        padding: 30px;
        margin-bottom: 1.5rem;
        font-size: 1.25rem;
        margin-left: -15px;

        @include media-breakpoint-down(sm) {
            margin-left: 0px;
            margin-top: -24px;
        }
    }
}
